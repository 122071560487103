var monthNames = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
const STATUS_CODES = {
    HTTP_SUCCESS: 200,
    HTTP_UNAUTHORIZED: 400,
};

const REGEX = {
    EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    OTP: /^\d{4}$/,
};
const TABLES = {
    SUBSCRIBED: "subscribed_user_table",
    EXPIRED: "expired_user_table",
    INACTIVE: "inactive_user_table",
};

const COURSE_IDS = [1, 2, 3, 4, 5, 6, 9];
export { STATUS_CODES, REGEX, monthNames, TABLES, COURSE_IDS };
