import axios from "axios";
import Cookies from "universal-cookie";
import { isNULLEMPTYORUNDEFINED } from "../../common/utility";

const handlePostUserPaymentDetails = async ({
    paymentDetails,
    setSnackBarOpen,
    setSnackBarClose,
    setLoading,
    courseIds,
}) => {
    try {
        setLoading(
            [...Array(courseIds.length).keys()].map((idx) => {
                return {
                    courseId: parseInt(courseIds[idx]),
                    hasCompleted: false,
                };
            })
        );
        for (let i = 0; i < courseIds.length; i++) {
            const courseId = courseIds[i];
            const isSuccessful = await postUserPaymentDetails({ ...paymentDetails, Course_Id: parseInt(courseId) });
            setLoading((prev) => {
                const newLoadingValues = prev;
                newLoadingValues[i].hasCompleted = true;
                newLoadingValues[i].isSuccessful = isSuccessful;
                return [...newLoadingValues];
            });
        }
        setSnackBarOpen(true);
    } catch (err) {
        // setLoading(false);
        setSnackBarClose(true);
        console.error("error is ", err);
    }
};

const postUserPaymentDetails = async (data) => {
    try {
        const cookies = new Cookies();
        await axios.post(process.env.REACT_APP_BACKEND_ADMIN_URL + "/externalPayments/postUserPaymentDetails", data, {
            headers: {
                "Content-Type": "application/json",
                authorization: cookies.get("authorization"),
            },
        });
        return true;
    } catch (err) {
        console.log("Payment Error:", err);
        return false;
    }
};

const handleGetUserPaymentHistory = async ({ email, courseIds, setPaymentHistory, setHistoryLoading }) => {
    try {
        setHistoryLoading(true);
        setPaymentHistory([]);
        for (let i = 0; i < courseIds.length; i++) {
            let data = {
                email: email,
                course_id: parseInt(courseIds[i]),
            };
            const res = await getUserPaymentHistory({ data });
            if (!isNULLEMPTYORUNDEFINED(res)) {
                setPaymentHistory((prev) => [...prev, ...res]);
            }
        }
    } catch (err) {
        console.error("Payment History failed:", err);
    } finally {
        setHistoryLoading(false);
    }
};

const getUserPaymentHistory = async ({ data }) => {
    try {
        const cookies = new Cookies();
        const res = await axios.post(
            process.env.REACT_APP_BACKEND_ADMIN_URL + "/externalPayments/getUserPaymentHistory",
            data,
            {
                headers: {
                    "Content-Type": "application/json",
                    authorization: cookies.get("authorization"),
                },
            }
        );
        return res.data;
    } catch (err) {
        console.error("Error:", err);
        return [];
    }
};

export { handlePostUserPaymentDetails, handleGetUserPaymentHistory };
