const Course_Id = {
    1: "Renaissance",
    2: "LLD",
    3: "Begin with c++",
    4: "Python",
    5: "Frontend with React",
    6: "DSA Essentials",
    9: "Java Springboot",
};

const Payment_Status = "captured";

const Renaissance_Course_ID = "1";

export { Course_Id, Payment_Status, Renaissance_Course_ID };
